import { BidirectionalAlignment } from '../../../../../Settings/challengeSettings/challengeSettings.types';

const rtl = {
  left: 'end',
  center: 'center',
  right: 'start',
};
const ltr = {
  left: 'start',
  center: 'center',
  right: 'end',
};
export const getBidirectionalAlignment = (
  alignment: BidirectionalAlignment,
  isRTL: boolean,
) => {
  if (isRTL) {
    return rtl[alignment];
  }
  return ltr[alignment];
};
